import React, { useEffect } from "react";
import image1 from "../../assets/home/about-2.jpg";
import image2 from "../../assets/home/about-3.jpg";
import image3 from "../../assets/home/classes-1.jpg";
import image4 from "../../assets/home/classes-2.jpg";
import image5 from "../../assets/home/classes-3.jpg";
import image6 from "../../assets/home/classes-4.jpg";
import image7 from "../../assets/home/classes-5.jpg";
import image8 from "../../assets/home/classes-6.jpg";

const ReactImageGallery = () => {
  useEffect(() => {
    // Select relevant React elements
    const filterButtons = document.querySelectorAll("#filter-buttons button");
    const filterableCards = document.querySelectorAll(
      "#filterable-cards .card"
    );

    // Function to filter cards based on filter buttons
    const filterCards = (e) => {
      document
        .querySelector("#filter-buttons .active")
        .classList.remove("active");
      e.target.classList.add("active");

      filterableCards.forEach((card) => {
        // show the card if it matches the clicked filter or show all cards if "all" filter is clicked
        if (
          card.dataset.name === e.target.dataset.filter ||
          e.target.dataset.filter === "all"
        ) {
          return card.classList.replace("hide", "show");
        }
        card.classList.add("hide");
      });
    };

    filterButtons.forEach((button) =>
      button.addEventListener("click", filterCards)
    );

    // Cleanup event listeners on component unmount
    return () => {
      filterButtons.forEach((button) =>
        button.removeEventListener("click", filterCards)
      );
    };
  }, []); // Empty dependency array ensures useEffect runs once after the initial render

  return (
    <div className="container">
      {/* Images Filter Buttons Section */}
      <div className="row mt-5" id="filter-buttons">
        <div className="col-12">
          <button className="btn mb-2 me-1 active" data-filter="all">
            Show all
          </button>
          <button className="btn mb-2 mx-1" data-filter="Education">
            Education
          </button>
          <button className="btn mb-2 mx-1" data-filter="Entertainment">
            Entertainment
          </button>
          <button className="btn mb-2 mx-1" data-filter="Libraries">
            Libraries
          </button>
        </div>
      </div>

      {/* Filterable Images / Cards Section */}
      <div className="row px-2 mt-4 gap-3" id="filterable-cards">
        {/* Map through your images and create card elements */}
        {[
          {
            name: "Entertainment",
            src: image1,
            title: "Playgroup",
          },
          {
            name: "Entertainment",
            src: image2,
            title: "Lights",
          },
          {
            name: "Libraries",
            src: image3,
            title: "Materials",
          },
          {
            name: "Entertainment",
            src: image4,
            title: "Building Block",
          },
          {
            name: "nature",
            src: image5,
            title: "Events",
          },
          {
            name: "Education",
            src: image6,
            title: "Libraries",
          },
          {
            name: "Education",
            src: image7,
            title: "Extra-classes",
          },
          {
            name: "Education",
            src: image8,
            title: "Futuristic",
          },
          // ... (similar objects for other images)
        ].map((image, index) => (
          <div
            key={index}
            className={`card p-0 ${image.name}`}
            data-name={image.name}
          >
            <img src={image.src} alt="img" />
            <div className="card-body">
              <h6 className="card-title">{image.title}</h6>
              <p className="card-text">{image.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReactImageGallery;
