import Home from "../views/home";
import Service from "../views/services";
import ContactUs from "../views/contactUs";
import AboutUs from "../views/aboutUs";
import SMediaService from "../views/sMediaService";
import Testimonial from "../views/Testimonial";
import Gallery from "../views/Gallery";
import Habbitskills from "../views/15hand9s";

const routes = [
  {
    components: <Home />,
    to: "/",
  },
  {
    components: <Service />,
    to: "/services",
  },
  {
    components: <ContactUs />,
    to: "/contactUs",
  },

  {
    components: <AboutUs />,
    to: "/aboutUs",
  },

  {
    components: <Testimonial />,
    to: "/test",
  },
  {
    components: <Gallery />,
    to: "/gallery",
  },
  {
    components: <Habbitskills />,
    to: "/habbits",
  },
];

export default routes;
