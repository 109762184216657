import React from "react";
import styles from "./Top.module.css";
import neuro from "../../assets/home/brain-map.jpg";
import vark from "../../assets/home/vark.png";
import multiple from "../../assets/home/Multiple-intelligence.jpg";
import bloom from "../../assets/home/blooom.jpg";
import maslow from "../../assets/home/malsow (1).jpg";

const Top = () => {
  return (
    <div className={`container overflow-hidden mt-4 mb-6`}>
      <p data-aos="slide-left" className={styles.subHeading}></p>

      <div className={` ${styles.contain} flex-column-reverse flex-md-row`}>
        <div className={`abouttext text-center pb-5`}>
          <p className={styles.about}> NEUROSCIENCE AND ENERGY SCIENCE</p>
          <p className={styles.creativity}>Key Differentiators</p>
          <p className={styles.content}>
            Kangaroo Kids International Preschool has pioneered a unique
            propietary pedagogical model that has been developed over 27 years
            with more than 150,000 man hours spent on the research and design
            which is based on contemporary education research, neuroscience and
            has recieved individual acclaim from many academicians and forums.
          </p>
          <h4>World class curriculum based on:</h4>
        </div>
        <div className="row neuro-content justify-content-center">
          {" "}
          <div
            data-aos="slide-right"
            data-aos-offset="350"
            className={`col-md-5 ${styles.imgContain} px-5`}
          >
            <img src={neuro} className="img-fluid" alt="neuroimage" />
            <p>NEUROSCIENCE</p>
          </div>
          <div
            className="col-md-5 "
            data-aos="slide-left"
            data-aos-offset="350"
          >
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <img src={vark} className="img-fluid px-4" alt="vark"></img>
                <p className="py-3 pb-4">Vark Theory</p>
                <img src={bloom} className="img-fluid" alt="neuroimage"></img>
                <p className="py-3">Blooms Taxonomy</p>
              </div>
              <div className="col-md-5 col-sm-6">
                {" "}
                <img
                  src={multiple}
                  className="img-fluid px-4"
                  alt="multiple"
                ></img>
                <p className="py-3 pb-4">Multiple Intelligence</p>
                <img src={maslow} className="img-fluid" alt="neuroimage"></img>
                <p className="py-3">Maslow Theory</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div data-aos="fade-up" className="row" style={{ textAlign: "left" }}>
        <div className={`col-md-6`}>
          <img src={img2} alt="" className={styles.img2} />
        </div>
      </div> */}
    </div>
  );
};

export default Top;
