import React from "react";
import card1 from "../../assets/15habbit/perseverance.png";
import card2 from "../../assets/15habbit/data-gathering.png";
import card3 from "../../assets/15habbit/data-management.png";
import card4 from "../../assets/15habbit/target.png";
import card5 from "../../assets/15habbit/shooting-star.png";
import card6 from "../../assets/15habbit/cognitive.png";
import card7 from "../../assets/15habbit/solution.png";
import card8 from "../../assets/15habbit/problem-solving.png";
import card9 from "../../assets/15habbit/decision-making.png";
import card10 from "../../assets/15habbit/brand.png";
import card11 from "../../assets/15habbit/online-lesson.png";
import card12 from "../../assets/15habbit/mind.png";
import card13 from "../../assets/15habbit/kindness.png";
import card14 from "../../assets/15habbit/humor.png";
import card15 from "../../assets/15habbit/independence.png";

const cardData = [
  {
    img: card1,
    title: "Persisting",
  },
  {
    img: card2,
    title: "Gathering Data Through All Senses",
  },
  {
    img: card3,
    title: "Managing Impulsivity",
  },
  {
    img: card4,
    title: "Thinking and Communicating with Clarity & Precision",
  },
  {
    img: card5,
    title: "Responding with wonderment & awe",
  },
  {
    img: card6,
    title: "Thinking About Your Thinking (Metacognition)",
  },
  {
    img: card7,
    title: "Creating Imagining & Innovating",
  },
  {
    img: card8,
    title: "Questioning & Problem Solving",
  },
  {
    img: card9,
    title: "Taking Responsible Risks",
  },
  {
    img: card10,
    title: "Applying Past Knowledge to New situations",
  },
  {
    img: card11,
    title: "Learning Continuously",
  },
  {
    img: card12,
    title: "Thinking Flexibly",
  },
  {
    img: card13,
    title: "Listening with Understanding and Empathy",
  },
  {
    img: card14,
    title: "Findind Humor",
  },
  {
    img: card15,
    title: "Thinking Independently",
  },
];

const Classes = () => {
  return (
    <div class="container-xxl py-5 skills">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <h1 class="mb-3">
            <span className="purple">15 Habbits</span>{" "}
            <span className="gold">of Mind </span>
          </h1>
          <p>
            To create life long learners. Enabling them forom a very young age
            to develop habbits that will allow them to be successful in what
            ever they choose to pursue
          </p>
        </div>
        <div class="row g-4 justify-content-center">
          {cardData.map((data) => (
            <div class=" col-md-2 col-sm-6 wow " data-wow-delay="0.1s">
              <div class="classes-item-1">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={data.img} alt="" />
                </div>
                <div class=" rounded p-4 pt-5 mt-n5">
                  <p class="d-block text-center  mt-3 mb-4" href="">
                    {data.title}{" "}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Classes;
