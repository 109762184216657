import React from "react";
import Footer from "../../components/Footer";
import Tournament from "../../components/Tournament";
import Classes from "../../components/Classes/Classes";
import SMediaServices from "../../components/SMediaServices/SMediaServices";

const ServicesHome = () => {
  return (
    <>
      {/* Banner Section  */}
      {/* Services Section  */}
      <section>
        <Tournament />
      </section>
      <section>
        <Classes />
      </section>

      <section>
        <SMediaServices />
      </section>
      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default ServicesHome;
