import React from "react";
import styles from "./Real.module.css";
import improveImg from "../../assets/learning.jpg";
import { Link } from "react-router-dom";

const Real = () => {
  return (
    <div className="mb-5  overflow-hidden tournament">
      <div className="container">
        <div className="row g-0">
          <div
            className={`col-md-6 ${styles.imgDiv}`}
            data-aos="slide-right"
            data-aos-offset="190"
          >
            <div className="container">
              <img src={improveImg} alt="" className={styles.img} />
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.text}>
              <p
                data-aos="slide-left"
                data-aos-offset="50"
                className={styles.subHeading}
              >
                Delivering through Fluidic Learning Methodology{" "}
              </p>
              <p
                data-aos="zoom-in"
                data-aos-offset="50"
                className={styles.content}
              >
                Responsive web design supply chain crowdfunding agile
                development analytics technology ownership startup. Network
                effects social proof user experience prototype buzz product
                management deployment metrics sales infographic equity holy
                grail crowdfunding release.{" "}
              </p>
              <div
                data-aos="zoom-in"
                data-aos-offset="50"
                style={{ textAlign: "left" }}
              >
                <Link className="btn btn-hero text-white " to="/contactUs">
                  LET&apos;S TALK
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Real;
