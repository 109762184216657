import React from "react";
import Footer from "../../components/Footer";
import Testimonial from "../../components/Testimonialspage/test";
import Clients from "../../components/Clientsreview/Client";
const individualServices = () => {
  return (
    <>
      {/* Banner Section  */}
      {/* <section>
        <Testimonial />
      </section> */}
      <section>
        <Clients />
      </section>
      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default individualServices;
