import React from "react";
import Footer from "../../components/Footer";
import Habbit from "../../components/15habbit/15habbit.jsx";
import Skills from "../../components/9skills/9skills";

const ServicesHome = () => {
  return (
    <>
      {/* Banner Section  */}
      {/* Services Section  */}

      <section>
        <Habbit />
      </section>
      <section>
        <Skills />
      </section>
      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default ServicesHome;
