import React from "react";
import styles from "./Real.module.css";
import improveImg from "../../assets/cool_kangaroo (1).png";
import { Link } from "react-router-dom";

const Real = () => {
  return (
    <div className="mb-5 overflow-hidden  real ">
      <div className="row g-0">
        <div className={`col-md-6 ${styles.imgDiv}`}>
          <img
            data-aos="slide-right"
            data-aos-offset="150"
            src={improveImg}
            alt=""
            className={styles.img}
          />
        </div>
        <div className="col-md-6">
          <div className={styles.text}>
            <p className={styles.heading}>Curriculum</p>
            <p className={styles.subHeading}>Our Purpose</p>
            <p className={styles.content}>
              To create a learning environment that accommodates all individual
              learners providing them with opportunities to maximise their
              inherent potential. Our ultimate and cherished dream is to capture
              the latest and most innovative learning and teaching techniques
              and to introduce them successfully in India and across the world.
            </p>
            <div data-aos="zoom-in" style={{ textAlign: "left" }}>
              <Link className="btn btn-hero text-white mt-3 " to="/services">
                Learn more{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Real;
