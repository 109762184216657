import React from "react";
import Footer from "../../components/Footer";
import Top from "../../components/AboutUs/Top";
import Menu from "../../components/Menu";
import AboutUsHome from "../../components/AboutUsHome";

const aboutUs = () => {
  return (
    <>
      {/* Banner Section  */}

      {/* Top Section  */}
      <section>
        <Top />
      </section>

      <section>
        <Menu />
      </section>
      <section>
        <AboutUsHome />
      </section>

      {/* Bottom Section  */}

      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default aboutUs;
