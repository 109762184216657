import React from "react";
import card1 from "../../assets/9skills/responsibility.png";
import card2 from "../../assets/9skills/world.png";
import card3 from "../../assets/9skills/e-book.png";
import card4 from "../../assets/9skills/adaptation.png";
import card5 from "../../assets/9skills/consultant.png";
import card6 from "../../assets/9skills/creative-process.png";
import card7 from "../../assets/9skills/entrepreneurship.png";
import card8 from "../../assets/9skills/partners.png";
import card9 from "../../assets/9skills/conversation.png";

const cardData = [
  {
    img: card1,
    title: "Personal Awareness and Responsibility",
  },
  {
    img: card2,
    title: "Global Mindset And Citizenship",
  },
  {
    img: card3,
    title: "Digital Literacy",
  },
  {
    img: card4,
    title: "Flexibility and Adaptability",
  },
  {
    img: card5,
    title: "A Critical Thinker and Creative Problem Solver",
  },
  {
    img: card6,
    title: "Creativity",
  },
  {
    img: card7,
    title: "Innovation and Entrepreneurship",
  },
  {
    img: card8,
    title: "Collaboration",
  },
  {
    img: card9,
    title: "Communication",
  },
];

const Skills = () => {
  return (
    <div class="container-xxl py-5 skills">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <h1 class="mb-3">
            {" "}
            <span className="purple">9 Future</span>{" "}
            <span className="gold">Skills </span>
          </h1>
          <p>
            As the world is constantly evolving and changing, we can neither
            predict how the future world will be for our children nor plan for
            it. What we can do is prepare them for it by developing the 9 Future
            Skills.
          </p>
        </div>
        <div class="row g-4 justify-content-center">
          {cardData.map((data) => (
            <div
              class=" col-md-4 p-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="classes-item1">
                <div class="bg-light rounded-circle w-75 mx-auto p-4">
                  <img class="img-fluid " src={data.img} alt="" />
                </div>
                <div class=" rounded p-4 pt-5 mt-n5">
                  <p class="d-block text-center  mt-3 mb-4" href="">
                    {data.title}{" "}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
