import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import card1 from "../../assets/sachin.jpg";
import card2 from "../../assets/Sharukh.jpg";

import { FaQuoteLeft } from "react-icons/fa";

const cardData = [
  {
    img: card2,
    title: "Shahrukh Khan",

    price:
      "Marvellous activities for the children – I wish the school all the best and hope they continue to do such marvellous activities for the children.Actually, if not for the children, I request it for the parents sake, please.",
  },
  {
    img: card1,
    title: "Sachin Tendulkar",
    price:
      "Thank you… Kangaroo Kids – I wish there was Kangaroo Kids when I was growing up. I’m grateful to get the opportunity to send my children to a school like this. I missed ICC Ceat Ranking Award Ceremony to be a part of this event.",
  },
];
export default function App() {
  return (
    <>
      <div class="container card-sliderbg   ">
        <div className="row justify-content-center overflow-hidden">
          <div
            className="col-md-6 text-start left-client "
            data-aos="slide-right"
            data-aos-offset="350"
          >
            <h2 className="pb-4">
              {" "}
              <span className="purple">What Clients Say</span>{" "}
              <span className="gold">About Us? </span>
            </h2>
            <p>
              At Kangaroo Kids, our parents' testimonials speak volumes about
              the quality of our care and education. With dedicated educators
              and tailored learning experiences, we nurture curiosity,
              creativity, and social skills in every child. Join us for a
              journey of growth and discovery.
            </p>
            <Link className="btn btn-hero text-white " to="/contactUs">
              LET&apos;S TALK
            </Link>
          </div>
          <div
            className="col-md-4 "
            data-aos="slide-left"
            data-aos-offset="150"
          >
            <div class="swiper swiperhero4">
              <div class="swiper-wrapper  ">
                {" "}
                <Swiper
                  slidesPerView={1}
                  spaceBetween={80}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  //   breakpoints={{
                  //     640: {
                  //       slidesPerView: 2,
                  //       spaceBetween: 100,
                  //     },
                  //     768: {
                  //       slidesPerView: 2,
                  //       spaceBetween: 100,
                  //     },
                  //     1024: {
                  //       slidesPerView: 3,
                  //       spaceBetween: 50,
                  //     },
                  //   }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper "
                >
                  <>
                    {cardData.map((data) => (
                      <SwiperSlide>
                        {" "}
                        <div class="swiper-slide swiper-slide-card-small p-4 ">
                          {" "}
                          <div className="text-start pb-3">
                            <FaQuoteLeft />
                          </div>
                          <div class="small-card-text pb-2">
                            <p>{data.price}</p>
                          </div>
                          <div class="small-card-image">
                            <img
                              src={data.img}
                              class="card-img-top"
                              alt="..."
                            />{" "}
                          </div>
                          <h4 className="gold pb-2">{data.title}</h4>
                        </div>{" "}
                      </SwiperSlide>
                    ))}
                  </>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
