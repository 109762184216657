import React, { useState, useEffect } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import styles from "./NavbarMain.module.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/kangarookids-logo.png";
const NavbarMain = () => {
  const [show, setShow] = useState(false);
  const [activeNav, setActiveNav] = useState([true, false, false, false]);
  const [expand, setExpand] = useState(false);

  const closeNav = () => {
    setExpand(false);
  };

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem("NavbarMain") != null) {
      let temp = JSON.parse(sessionStorage.getItem("NavbarMain"));
      setActiveNav([...temp]);
    }
  }, []);

  const handleActiveNav = (i) => {
    let temp = activeNav;
    temp = temp.map((x) => (x = false));
    temp[i] = true;
    setActiveNav([...temp]);
    sessionStorage.setItem("NavbarMain", JSON.stringify(temp));
  };

  return (
    <>
      <Navbar
        style={{
          backgroundColor: "#5b3e8d",
          boxShadow: "1px 1px 10px rgb(0 0 0 / 0.4)",
        }}
        variant="light"
        expand="lg"
        sticky="top"
        onToggle={() => {
          setExpand((prevState) => !prevState);
        }}
        expanded={expand}
      >
        <Container>
          <div className="logoimg">
            <img src={logo} className="logoimg" />
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{ marginLeft: "auto" }}>
              <NavLink
                to="/"
                className={`${styles.nav_text} nav-link ${
                  activeNav[0] ? styles.active : ""
                }`}
                style={{ marginTop: "8px" }}
                onClick={() => {
                  handleActiveNav(0);
                  closeNav();
                }}
              >
                HOME
              </NavLink>

              <NavLink
                to="/aboutUs"
                className={`${styles.nav_text} nav-link ${
                  activeNav[1] ? styles.active : ""
                }`}
                style={{ marginTop: "8px" }}
                onClick={() => {
                  handleActiveNav(1);
                  closeNav();
                }}
              >
                ABOUT US
              </NavLink>

              <NavLink
                to="/services"
                className={`${styles.nav_text} nav-link ${
                  activeNav[2] ? styles.active : ""
                }`}
                style={{ marginTop: "8px" }}
                onClick={() => {
                  handleActiveNav(2);
                  closeNav();
                }}
              >
                PROGRAMS
              </NavLink>

              <NavLink
                to="/test"
                className={` ${styles.nav_text} nav-link ${
                  activeNav[3] ? styles.active : ""
                }`}
                style={{ marginTop: "8px" }}
                onClick={() => {
                  handleActiveNav(3);
                  closeNav();
                }}
              >
                TESTIMONIALS
              </NavLink>
              <NavLink
                to="/gallery"
                className={` ${styles.nav_text} nav-link ${
                  activeNav[4] ? styles.active : ""
                }`}
                style={{ marginTop: "8px" }}
                onClick={() => {
                  handleActiveNav(4);
                  closeNav();
                }}
              >
                GALLERY
              </NavLink>
              <NavLink
                to="/contactUs"
                className={` ${styles.nav_text} nav-link ${
                  activeNav[5] ? styles.active : ""
                }`}
                style={{ marginTop: "8px" }}
                onClick={() => {
                  handleActiveNav(5);
                  closeNav();
                }}
              >
                CONTACT US
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarMain;
