import React from "react";
import styles from "./SMediaServices.module.css";
import img1 from "../../assets/sMediaService/Curriculum-02.png";
import img2 from "../../assets/sMediaService/9-FS.png";
import img3 from "../../assets/sMediaService/2149355208.jpg";
import { Link } from "react-router-dom";

const SMediaServices = () => {
  return (
    <>
      <div className="overflow-hidden">
        {/* Top  */}
        <div className="row mt-5 mx-0 flex-column-reverse flex-md-row ">
          <div className="col-md-6 px-0">
            <div className={styles.topContain}>
              <p className={`${styles.headTop}`}>
                A World Within{" "}
                <span style={{ color: "rgb(91, 62, 141)" }}>
                  For The New-Age Child
                </span>
              </p>
              <span className={styles.identityTop}></span>
              <p className={styles.content}>
                We have REimagined and REcreated a whole new concept in
                learning. Setting up new learning experiences for our young
                minds to make their first five years count.
              </p>
            </div>
          </div>
          <div className="col-md-6 px-0">
            <img
              src={img1}
              alt=""
              className={styles.img1}
              data-aos="slide-left"
              data-aos-offset="250"
            />
          </div>
        </div>

        {/* Middle  */}
        <div className="container">
          <div
            className={`row ${styles.contain} flex-column-reverse flex-md-row`}
          >
            <div className={`col-md-6 ${styles.middleContain}`}>
              <img
                src={img2}
                alt=""
                className={styles.img2}
                data-aos="slide-right"
                data-aos-offset="250"
              />
            </div>
            <div className="col-md-6">
              <p className={styles.heading}>
                {" "}
                9{" "}
                <span style={{ color: "rgb(91, 62, 141)" }}>Future Skills</span>
              </p>
              <span className={styles.identityTop}></span>
              <p className={styles.text}>
                As the world is constantly evolving and changing, we can neither
                predict how the future world will be for our children nor plan
                for it. What we can do is prepare them for it by developing the
                9 Future Skills. No matter how the world changes, these future
                skills will equip our children to make the right choices that
                will lead towards a life of success and fulfillment.
                <br></br>
                <Link className="btn btn-hero text-white mt-3 " to="/habbits">
                  Learn more
                </Link>
              </p>{" "}
            </div>
          </div>
        </div>

        {/* Bottom  */}
        <div className="container">
          <div className={`row ${styles.contain}`}>
            <div className="col-md-6">
              <p className={styles.heading1}>
                <span style={{ color: "rgb(91, 62, 141)" }}>15</span> Habits of
                Mind
              </p>
              <span className={styles.identityTop}></span>

              <p className={styles.text1}>
                We have picked 15 Habits of Minds that are developmentally
                appropriate because we believe that children as young as 2 years
                old need to be exposed to them with deliberate practices and
                focused attention so that they can grow as thinkers and navigate
                and thrive in the face of unprecedented change. Adapted from
                Arthur Costa’s work Developing Minds.
                <br></br>
                <Link className="btn btn-hero text-white mt-3 " to="/habbits">
                  Learn more{" "}
                </Link>
              </p>
            </div>
            <div className={`col-md-6 ${styles.bottomContain}`}>
              <img
                src={img3}
                alt=""
                className={styles.img2}
                data-aos="slide-left"
                data-aos-offset="300"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SMediaServices;
