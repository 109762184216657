import React from "react";
import styles from "./Top.module.css";
import img1 from "../../../assets/home/kangarookids.png";
import img2 from "../../../assets/aboutUs/Aboutus2.jpg";

const Top = () => {
  return (
    <div className={`container overflow-hidden mt-4 mb-6`}>
      <p data-aos="slide-left" className={styles.subHeading}>
        Why Kangaroo Kids is one of the top Kindergarten Schools ?
      </p>
      <p data-aos="fade-up" data-aos-offset="80" className={styles.content}>
        The trust and goodwill that we have built over the years is based on the
        passion we have invested in education and evolved learning systems. We
        believe that every child deserves an atmosphere of creativity,
        compassion and confidence. At all Kangaroo Kids play schools, nursery
        schools and kindergartens across India and abroad, we ensure a safe,
        bias-free environment for children to learn and blossom in.
      </p>

      <div className={`row ${styles.contain} flex-column-reverse flex-md-row`}>
        <div
          data-aos="slide-right"
          data-aos-offset="50"
          className={`col-md-6 abouttext`}
        >
          <p className={styles.about}>The Kangaroo Kids Story </p>
          <p className={styles.creativity}>India, Dubai, Maldives, Quatar</p>
          <p className={styles.contn}>
            Our goal was to change the mental model of schooling (rote-learning,
            unhealthy competition, stressful examinations) and in the process,
            we changed the teaching-learning cycle by allowing children to be
            thinkers, innovators, lifelong learners and problem solvers. I
            believe that in the real world Emotional Quotient (EQ) is more
            important than Intelligence Quotient (IQ) to be a successful human
            being
          </p>
        </div>
        <div
          data-aos="slide-left"
          data-aos-offset="50"
          className={`col-md-6 ${styles.imgContain}`}
        >
          <img src={img1} alt="" className={styles.img1} />
        </div>
      </div>
      {/* <div data-aos="fade-up" className="row" style={{ textAlign: "left" }}>
        <div className={`col-md-6`}>
          <img src={img2} alt="" className={styles.img2} />
        </div>
      </div> */}
    </div>
  );
};

export default Top;
