import React from "react";
import ContactUsForm from "../../components/ContactUsForm";
import Footer from "../../components/Footer";

const ServicesHome = () => {
  return (
    <>
      {/* Banner Section   Form Section  */}
      <section>
        <ContactUsForm />
      </section>
      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default ServicesHome;
