import React from "react";
import ChoosingUs from "../../components/ChoosingUs";
import Neuro from "../../components/Neuro";
import Real from "../../components/Real";
import Hero from "../../components/Hero/Hero.jsx";
import Footer from "../../components/Footer";
import AboutUsHome from "../../components/AboutUsHome";

const Home = () => {
  return (
    <>
      <section>
        <Hero />
      </section>

      <section>
        <AboutUsHome />
      </section>

      <section>
        <Real />
      </section>

      <section>
        <ChoosingUs />
      </section>
      {/* Testimonial Section  */}

      <section>
        <Neuro />
      </section>
      {/* Footer Section  */}
      <section>
        <Footer />
      </section>
    </>
  );
};

export default Home;
