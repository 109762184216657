import React from "react";
import styles from "./AboutUsHome.module.css";
import img1 from "../../assets/home/about-1.jpg";
import img2 from "../../assets/home/about-2.jpg";
import img3 from "../../assets/home/about-3.jpg";
import { Link } from "react-router-dom";

const AboutUsHome = () => {
  return (
    <>
      <div className="AboutusHome">
        <div className={`container ${styles.contain}`}>
          <div className="row overflow-hidden">
            <div className="col-md-6">
              <p className={styles.head}>About us</p>
              <p className={styles.heading}>
                Learning is and exciting challenge, an adventure for your child{" "}
              </p>
              <div>
                <p className={styles.content}>
                  In 1993, Kangaroo Kids International Preschool pioneered a
                  whole new concept in learning as we knew that the conventional
                  rote system of learning would not equip children with skills
                  and abilities for the dawn of a new age of information. We
                  believe that children learn best when they are given a choice
                  and voice within an emotionally safe environment. They blossom
                  when they are nurtured by those who have their best interests
                  at heart and use evidence-based teaching-learning strategies
                  that are supported by years of research and up-to-date
                  methods.
                </p>
              </div>
              <div style={{ textAlign: "left" }}>
                <Link to="/Aboutus" className="btn btn-hero text-white">
                  {" "}
                  Learn more{" "}
                </Link>
              </div>
            </div>

            <div
              class="col-lg-6 about-img wow fadeInUp overflow-hidden mt-5"
              data-wow-delay="0.5s"
            >
              <div class="row">
                <div class="col-12 text-center">
                  <img
                    class="img-fluid w-75 rounded-circle bg-light p-3"
                    src={img1}
                    alt=""
                  />
                </div>
                <div class="col-6 text-start" style={{ marginTop: "-150px" }}>
                  <img
                    class="img-fluid w-100 rounded-circle bg-light p-3"
                    src={img2}
                    alt=""
                  />
                </div>
                <div class="col-6 text-end" style={{ marginTop: "-150px" }}>
                  <img
                    class="img-fluid w-100 rounded-circle bg-light p-3"
                    src={img3}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsHome;
