import React from "react";
import styles from "./Real.module.css";
import improveImg from "../../assets/kangaroo3 (1).png";

const Real = () => {
  return (
    <div className="Menucard">
      {" "}
      <div className="mb-5 mt-5 overflow-hidden container py-5 ">
        <div className=" overflow-hidden ">
          {" "}
          <div className="row ">
            <div className={`col-md-6 ${styles.imgDiv}`}>
              <img
                data-aos="slide-right"
                data-aos-offset="150"
                src={improveImg}
                alt=""
                className={styles.img}
              />
            </div>
            <div className="col-md-6 container">
              <div className={styles.text}>
                <p className={styles.subHeading}>
                  Our <span className="gold">Vision</span>{" "}
                </p>
                <p className={styles.content}>
                  To spark human greatness in every single child by discovering
                  and then nurturing each child’s unique gift.
                </p>
              </div>
              <div className={styles.text}>
                <p className={styles.subHeading}>
                  Our <span className="gold">Mission</span>{" "}
                </p>
                <p className={styles.content}>
                  To create a learning environment that accommodates all
                  individual learners, providing them with opportunities to
                  maximise their inherent potential.
                </p>

                {/* <div data-aos="zoom-in" style={{ textAlign: "left" }}>
                <button className={`btn custom_btn ${styles.btn}`}>
                  LET&apos;S TALK
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Real;
